import Head from "next/head"
import Script from 'next/script'
import styles from "@/styles/home.module.scss"
import DefaultLayout from "@/components/DefaultLayout"
import Swiper from "@/components/Swiper"
import Exhibition from "@/components/Exhibition"
import CrazySaleA from "@/components/CrazySaleA"
import CrazySaleB from "@/components/CrazySaleB"
import ShopInfo from "@/components/ShopInfo"
import WeeklyDeals from "@/components/WeeklyDeals"
import Image from "next/image"
import Link from 'next/link'
import { useState, useEffect } from "react"
import { useRouter } from 'next/router'
import MiddleSubscribe from "@/components/MiddleSubscribe"
import Recommend from '@/components/Recommend'
import Reviews from '@/components/Reviews'
import SubscribeCloseIcon from '@/svgs/subscribeCloseIcon.svg'
import { GetStaticProps } from 'next'
import { getCookie, setCookie } from '@/utils/cookie'
import homeApi from '@/service/modules/homeApi'
import systemApi from '@/service/modules/systemApi'
import { abTestCalculate } from "@/utils/ABtest"
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain
const imageDomain = publicRuntimeConfig.imageDomain

interface BannerItem {
  imgUrl: string;
  title: string;
  link: string;
}
interface ProductItem {
  type: string,
  productID: string;
  productName: string;
  url: string;
  imgUrl: string;
  promotion: string;
  review: number;
  reviewCount: number;
  price: number;
  promotionPrice: number;
  tagTitle: string;
  tagBgColor: string;
  tagFontColor: string;
  pageOffset: number;
  freeShipping: number;
  onSale: number;
  reduced: number;
  isNew: number;
  isEmailOnly: any,
  promotionTypeID: string | number;
}
interface ReviewItem {
  name: string;
  star: number,
  review: string;
  createDateTime: string;
}

interface crazyDealDataType {
  productID: string;
  name: string;
  url: string;
  filename: string;
  price: number;
  normalPrice: number;
  crazyDeal: {
    hoursLeft: number;
    minutesLeft: number;
    secondsLeft: number;
  } | undefined
}

interface WeeklySalesType {
  mainImgUrl: string;
  ipadImgUrl: string;
  title: string;
  link: string;
  productList: ProductItem[];
}

interface moonCakeProductItem {
  productID: string;
  name: string;
  customImgUrl: string;
  url: string;
  price: number;
  normalPrice: number;
  promotion: {
    promotionRate: string;
  };
}

interface moonCakeItemType {
  title: string,
  backgroundStyle: any,
  titleStyle: any,
  priceStyle: any,
  borderStyle: any,
  link: string,

  productList: moonCakeProductItem[]
}

interface HomeProps {
  slideBanner: BannerItem[];
  floatBanner: BannerItem;
  midBanner: BannerItem;
  midBannerPad: BannerItem;
  footBanner: BannerItem;
  reviewData?: ReviewItem[];
  reviewSummary?: any;
}

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  try {
    const [
      bannersResponse,
      reviewResponse
    ] = await Promise.all([
      systemApi.getFullBanners(),
      homeApi.getHomeReviews()
    ])
    if (bannersResponse.data && bannersResponse.data.home && reviewResponse.data) {
      return {
        props: {
          slideBanner: bannersResponse.data.home.slideBanner,
          floatBanner: bannersResponse.data.home.floatBanner,
          midBanner: bannersResponse.data.home.midBanner,
          midBannerPad: bannersResponse.data.home.midBannerPad,
          footBanner: bannersResponse.data.home.footBanner,
          reviewData: reviewResponse.data.reviews,
          reviewSummary: reviewResponse.data.reviewSummary
        }
      }
    } else {
      // 数据无效时返回空的 props 对象或者 notFound 对象
      return { notFound: true }
    }
  } catch (error) {
    return { notFound: true }
  }
}

export default function Home({ slideBanner, floatBanner, midBanner, midBannerPad, footBanner, reviewData, reviewSummary }: HomeProps) {
  const router = useRouter()

  /**
   * 页面底部的Subscribe
   * 需要滚动到一定位置再展示
   */
  const [showFixedSubscribe, setShowFixedSubscribe] = useState(false)
  const [fixedSubscribeValue, setFixedSubscribeValue] = useState("")
  const handleFixedSubscribeChange = (event: any) => {
    setFixedSubscribeValue(event.target.value);
  };
  const handleSendFixedSubscribe = () => {
    window.location.href = `https://${window.location.host}/newsletters?email=${fixedSubscribeValue}`
  }
  //  隐藏固定Subscribe窗口
  const handleHideFixedSubscribe = () => {
    setShowFixedSubscribe(false)
  }

  const fixedSubscribe = (
    <div className={styles["fixed-subscribe"]}>
      <SubscribeCloseIcon 
        onClick={handleHideFixedSubscribe} 
        className={`bi bi-x-circle-fill ${styles["subscribe-close"]}`}
      ></SubscribeCloseIcon>

      <Link href={`${apiDomain}/newsletters`} legacyBehavior>
        <a>
          <Image
            alt="Subscribe"
            width={350}
            height={161}
            src={floatBanner.imgUrl}
          />
        </a>
      </Link>

      <div className={styles["subscribe-form"]}>
        <input
          value={fixedSubscribeValue}
          onChange={handleFixedSubscribeChange}
          placeholder="Email Address"
        ></input>
        <div
          className={styles["subscribe-btn"]}
          onClick={handleSendFixedSubscribe}
        >
          Subscribe
        </div>
      </div>
    </div>
  )

  // 获取CrazyDeal数据
  const [crazyDealData, setCrazyDealData] = useState<crazyDealDataType | null>(null)
  const getCrazyDealData = async () => {
    try {
      const { data } = await systemApi.getCrazyDealOtd()
      setCrazyDealData(data.product || {})
    } catch (error) {
      return { notFound: true }
    }
  }

  // 获取weeklySales数据
  const [weeklySalesData, setWeeklySalesData] = useState<WeeklySalesType | null>(null)
  const getWeeklySalesData = async () => {
    try {
      const { data } = await homeApi.getWeeklyDeals({ limit: 32 })

      if (data && data.weeklySales) {
        data.weeklySales.pageList.items.forEach((item: any) => {
          item.type = 'CrazyDeal'
          item.productName = item.name
          item.promotionPrice = item.price
          item.price = item.normalPrice
          item.imgUrl = item.filename
          item.inStock = item.inStock >= 1 ? 1 : 0
  
          item.reviewCount = item.review.total
          item.review = item.review.avgRank
        })

        let dataObj = {
          mainImgUrl: data.weeklySales.mainImgUrl,
          ipadImgUrl: data.weeklySales.ipadImgUrl,
          title: data.weeklySales.title,
          link: data.weeklySales.link,
          productList: data.weeklySales.pageList.items
        }
        
        setWeeklySalesData(dataObj)
      }

    } catch (error) {
      return { notFound: true }
    }
  }

  // 获取weeklySales数据
  const [moonCakeData, setMoonCakeData] = useState<moonCakeItemType[]>([])
  const getMoonCakeData = async () => {
    try {
      const { data } = await homeApi.getMoonCakeData()

      if (data) {
        // 处理moonCakeData的数据格式，整合为数组
        const moonCakeList: moonCakeItemType[] = Object.values(data).flat() as moonCakeItemType[];

        moonCakeList.forEach((item: any) => {
          item.productList = item.pageList.items || []
        })

        // 剔除掉没有产品的模块
        let filterList = moonCakeList.filter((item: moonCakeItemType) => item && item.productList && item.productList.length)

        setMoonCakeData(filterList)
      }
    } catch (error) {
      return { notFound: true }
    }
  }

  // 获取Recommend数据
  let [recommendData, setRecommendData] = useState<ProductItem[]>([])
  let [recommendStart, setRecommendStart] = useState(0)
  let [recommendLoading, setRecommendLoading] = useState(true)
  const getRecommendData = async () => {
    try {
      interface DataObj {
        start?: number;
        perPage: number;
      }
      // 创建一个对象符合上面定义的类型
      const dataObj: DataObj = {
        start: recommendStart,
        perPage: recommendStart > 0 ? 24 : 36,
      }
      if (!recommendStart) delete dataObj.start;

      const { data } = await homeApi.getHomeRecommended(dataObj)
      if (data) {
        setRecommendLoading(false)

        let fetchData = data.pageList.items
        fetchData.forEach((item: any) => {
          item.productName = item.name
          item.promotionPrice = item.price
          item.price = item.normalPrice
          item.imgUrl = item.filename
          item.inStock = item.inStock >= 1 ? 1 : 0

          item.reviewCount = item.review.total
          item.review = item.review.avgRank
        })
        let lastData = data.pageList.items.slice(-1)
        if (lastData.length) {
          setRecommendStart(lastData[0]?.pageOffset)
        }
        setRecommendData((prevData) => {
          if (fetchData.length) {
            // 这里保证了只有当fetchData不为空时，才会合并数据
            const newRecommend = [...prevData, ...fetchData];
            return newRecommend as ProductItem[];
          } else {
            // 当fetchData为空数组时，返回prevData，防止返回undefined。
            return prevData;
          }
        })
      }
    } catch (error) {
      setRecommendLoading(false)
      return { notFound: true }
    }
  }
  const viewMoreRecommendData = () => {
    getRecommendData()
    setRecommendLoading(true)
  }

  useEffect(() => {
    getCrazyDealData()

    getWeeklySalesData()

    getMoonCakeData()

    getRecommendData()

    // 监听滚动事件
    function handleScroll() {
      let target = document.getElementById('middleSubscribe') as Element
      if (target?.getBoundingClientRect().top <= 300) {
        setShowFixedSubscribe(true)
        setCookie('first_visit_next', 'no', { expires: 30000, secure: true })
        window.removeEventListener('scroll', handleScroll)
      }
    }

    /**
     * 先从cookie读取first_visit_next，判断是否需要展示
     */
    if (!getCookie('first_visit_next')) {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * UVP 的 AB test
   */
   const [abTestVersion, setAbTestVersion] = useState('A');

  //  useEffect(() => {
  //    // 先看看cookie，当前是A还是B，cookie没有的话，从abTestCalculate拿
  //    if (getCookie('X-Service-Group-AB')) {
  //      setAbTestVersion(getCookie('X-Service-Group-AB') || 'A')
  //    } else {
  //      abTestCalculate("2024-02-05_ABTest").then((res: unknown) => {
  //        console.log(`Once you enter the page, the traffic starts being counted, and the traffic falls on ${res as string}`)
  //        setAbTestVersion(res as string || 'A');
  //      })
  //    }
  //  }, []);

  return (
    <>
      <Head>
        <title>
          CrazySales® Official Site - Cheap Online Shopping Australia Store.
        </title>

        <meta
          name="keywords"
          content="Online Shopping Australia, online shopping sites, Home Shopping, Shopping Deals, Best Deals, Home Garden, Pet Supplies, Sports Fitness, Bedding Bath, Buy Online, Discount Shopping, Toys Hobbies, Beauty Health, Crazy Sales, Computers, IT, Christmas, Electronics, Automotive, Tools, Appliances, Baby, CrazySales"
        />
        <meta
          name="description"
          content="Australia's #1 online shopping website! Cheap bargains & discounts with the top online shopping Australia store! From furniture, to toys & hobbies, to appliances & pet supplies -- we've got it all, with free shipping on selected items!"
        />
      </Head>

      {/* criteo */}
      <Script id="criteo" src="https://static.criteo.net/js/ld/ld.js"></Script>
        <Script
          id="criteoConfig"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
              { event: 'setAccount', account: 11526 },
              { event: 'setSiteType', type: "d" },
              { event: 'viewHome' }
            );
          `}} 
        />

        <DefaultLayout wideScreen={true}>
          <main className={styles["page-index"]}>
            <div className={`wapper ${ abTestVersion == 'B' ? 'padding-top-16' : '' }`}>
              <div className={styles["advertise-box"]}>
                <Swiper bannerList={slideBanner} />

                {/* {
                  abTestVersion == 'A'
                  ?
                  <CrazySaleA crazyDealData={crazyDealData} />
                  :
                  <CrazySaleB crazyDealData={crazyDealData} />
                } */}
                <CrazySaleA crazyDealData={crazyDealData} />
              </div>
            </div>

            {
              // !!(abTestVersion == 'A') &&
              <div className={styles["shop-info-box"]}>
                <div className={styles["shop-info"]}>
                  <ShopInfo />
                </div>
              </div>
            }

            {
              !!(weeklySalesData?.title) &&
              <div className={styles["crazy-deals"]}>
                <WeeklyDeals weeklySalesData={weeklySalesData} />
              </div>
            }
            
            {
              !!(moonCakeData && moonCakeData.length) &&
              <div className={styles["exhibition-box"]}>
                <Exhibition moonCakeData={moonCakeData} />
              </div>
            }

            <div className={styles["middle-subscribe-box"]}>
              <MiddleSubscribe midBanner={midBanner} midBannerPad={midBannerPad} />
            </div>

            <div className={styles['recommend-box']}>
              <Recommend recommendData={recommendData} loading={recommendLoading} viewMore={viewMoreRecommendData} />
            </div>

            <div className={styles['reviews-box']} id={'homeReviews'}>
              <Reviews footBanner={footBanner} reviewData={reviewData} reviewSummary={reviewSummary} />
            </div>

            {
              showFixedSubscribe &&
              <>
                {fixedSubscribe}
              </>
            }
          </main>
        </DefaultLayout>
    </>
  )
}